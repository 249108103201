import { React } from "react";

const Ccctv = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe title="myFrame" className=" w-full h-full border-0"
        src="https://docs.google.com/spreadsheets/d/18xu0xxHOiIAZ0bHKqQK1W21AdxhGzvWVU_KZzjap__c/edit?gid=0#gid=0" 
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox single=true;widget=true;headers=false"></iframe></div>
    )
}
export default Ccctv;