import { RiBillLine, RiPencilFill, RiEraserFill ,RiCloseLine} from "react-icons/ri";
import { Link } from "react-router-dom";
import { React, useState, useEffect } from "react";
import Permisos from "../../data/Permisos";
const Settings = ({autusuario}) => {
const activemodal= true;
const eseditable= true;
console.log(Permisos)
  return (
    <>
      <div className="h-screen w-full p-2 bg-white">
        <div>
          <h1 className="text-2xl text-gray-600">Permisos</h1>
        </div>
        <div className="flex py-5">
          <button className=" hidden flex items-center gap-2 h-[35px] bg-buttpurple text-base text-white p-2 rounded-[5px]">
            <RiBillLine /> Nueva
          </button>
        </div>
        <div className="w-full h-[auto] mb-[5vh] overflow-y-auto overflow-x-auto bg-white">
          <table className="w-full">
            <thead className="w-full text-xs font-semibold text-white bg-secondary">
              <tr>
                <th class="py-2 whitespace-nowrap font-semibold text-left"></th>
                <th className="py-2 whitespace-nowrap font-semibold text-left">
                  Usuarios
                </th>
                <th className="py-2 whitespace-nowrap font-semibold text-left">
                  Correo electronico
                </th>
              </tr>
            </thead>

            <tr>
              <td className="flex py-2 w-[3vw]  gap-1 text-gray-800 text-left">
                <div className="bg-purple-800 p-1 rounded-full w-[24px] h-[24px]">
                  <Link className="text-center">
                    <RiPencilFill className="text-white" />
                  </Link>
                </div>
                <div className="bg-purple-600 p-1 rounded-full w-[24px] h-[24px]">
                  <Link className="text-center">
                    <RiEraserFill className="text-white" />
                  </Link>
                </div>
              </td>
              <td className="py-2 text-gray-800 text-left">MAURICIO VALDES</td>
              <td className="py-2 text-gray-800 text-left">
                correo@correo.com.mx
              </td>
            </tr>

            <tr>
            <td className="flex py-2 w-[3vw]  gap-1 text-gray-800 text-left">
              <div className="bg-purple-800 p-1 rounded-full w-[24px] h-[24px]">
                  <Link className="text-center">
                    <RiPencilFill className="text-white" />
                  </Link>
                </div>
                <div className="bg-purple-600 p-1 rounded-full w-[24px] h-[24px]">
                  <Link className="text-center">
                    <RiEraserFill className="text-white" />
                  </Link>
                </div>
              </td>
              <td className="py-2 text-gray-800 text-left">
                JUAN CARLOS ARREOLA LOPEZ
              </td>
              <td className="py-2 text-gray-800 text-left">
                correo@correo.com.mx
              </td>
            </tr>
          </table>
          {/* formulario permisos  */}
          <div
      className={`${activemodal ? "" : ""} 
      fixed 
      flex justify-center items-center ml-3
      inset-0 bg-indigo-500 z-40 bg-opacity-40 background-blur-sm `}
    >
            <div
        className="w-[92%] h-[95%]
      flex flex-col justify-around items-center 
      bg-white pl-2 rounded gap-2 "
      ></div>
              <div className="flex flex-row w-full justify-between items-center">
          <h1 className="px-1 text-2xl text-bold">
            {eseditable
              ? "Editar carta notificación "
              : "Nueva carta notificación "}
          </h1>
          
          <Link
           
            className="bg-buttpurple w-[25px] h-[25px] rounded text-white p-1"
          >
            <RiCloseLine />
          </Link>
        </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default Settings;
