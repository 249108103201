import { Link } from "react-router-dom";
import logosinestry from "../../auth/Login/logo sinestry fgr.png";
import imagenondas from "../../auth/Login/fondo ondas sinestry.png";
const Splash2 =({ autusuario }) => {
  const email = autusuario.email;
  return (
    <div class="container  justify-center">
      <div class="absolute w-[70%] rounded-[2.5vw] shadow-gray-300 shadow -mt-[2vh] ml-[13vw] flex flex-col justify-center">
        <div className="w-full flex flex-row justify-center pt-5">
          <img class="logo-card" src={logosinestry} alt="" />
          
        </div>
        <div className="w-full flex flex-row justify-center">
         
          <span className="text-[3rem] justify-center items-center font-bold text-moradoSinestry">
            Bienvenido a Sinestry App
          </span>
        </div>
        <div className="w-full flex justify-center ">
          <div className="w-full gap-2 px-10">
            <p className="py-5 text-justify">
              Aquí encontrarás información relevante del siniestro
              FGR-00087/2023 ocurrido el 25 de octubre de 2023, en el Hangar
              propiedad de la Fiscalía General de la República, ubicado en el
              Aeropuerto Internacional “Juan N. Álvarez” en Acapulco de Juárez,
              Guerrero.
            </p>
            <p className="py-5 text-justify">
              La información para un mayor control y seguimiento se encuentra
              seccionada de acuerdo con las reclamaciones parciales que se
              presentaron a la aseguradora, a la documentación requerida para el
              nuevo proyecto y resumen de datos relevantes.
            </p>
          </div>
        </div>
        <div className="w-full grid grid-cols-3 gap-4 py-[2vh]">
          <div className="flex flex-col justify-center items-center col-2">
            <button class="card-button w-[20vw]">
              <i class="bx bx-spreadsheet"></i> Formal Reclamacíon
            </button>
            <ul>
            <li className="hover:font-bold">              
                <Link
                  to="/rem/remocion">  Remoción de escombros </Link>
              </li>
           
              <li>               
              
                Obra civil
                <ul className="pl-5">
                <li className="hover:font-bold"> <Link
                  to="och/dicthangar">
                    <i class="bx bx-chevron-right"></i>
                    Obra civil de hangar</Link>
                  </li>
                  <li className="hover:font-bold">
                     <Link
                  to="/oca/ciboficina">
                    <i class="bx bx-chevron-right"></i>
                    Obra civil de oficina </Link>
                  </li>
                </ul>
              </li>
              <li className="hover:font-bold">
              <Link
                  to="/cct/cctv"> 
               CCTV </Link> 
              </li>
              <li className="hover:font-bold">
                <Link
                  to="/cya/cristalesaluminio">
                Cristales </Link>
              </li>
              <li className="hover:font-bold">
              <Link
                  to="/ayc/acondicionados">
               Aire acondicionado </Link>
              </li>
              <li className="hover:font-bold">
                 <Link
                  to="/cnt/contenido">
               Contenidos </Link>
              </li>
              {(email=='gustavo.rueda@fgr.sinestryapp.com'||email=='miguel.chavez@fgr.sinestryapp.com'||email=='noe.campoy@fgr.sinestryapp.com')?(""):(
              <li> 
                 <Link
                  to="/cnt/concentrado">              
               Concentrados</Link> 
              </li>)}
            </ul>
          </div>
          <div className="col-1">
            <button class="card-button w-[20vw]">
             <i class="bx bx-bar-chart-alt-2"></i> Proyecto
            </button>
            <ul>
            <li className="hover:font-bold">              
             <Link to="/pro/proyecto"> <div className="w-[20vw] px-5 text-center">Documental relativa al proyecto ejecutivo para la creación del Hangar </div>  </Link>          
              </li>
            </ul>
          </div>
          <div className="col-1 ">
            <button class="card-button w-[20vw]">
            <i class="bx bx-bar-chart-alt-2"></i> Estadísticas
            </button>
            <ul>
            <li className="hover:font-bold"> 
                <Link to="/est/estaditicafgr">              
                <div className="w-[20vw] px-5 text-center">Resumen de datos relativos a los importes reclamados, convenidos y las etapasen las que se encuentran.</div>            
            </Link>   
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Splash2;
