import * as React from "react";
import Login from "./auth/Login";
import Dashboard from "./paginas/Admin/Hm/Dashboard";
import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";
import { HashRouter , Route, Routes } from "react-router-dom";
// Auth
import LayoutAuth from "./layouts/LayoutAuth";
import LayoutAd1 from "./layouts/LayoutAd1";
import LayoutSplash from "./layouts/LayoutSplash";
import Usuarioform from "./paginas/Admin/Adm/Usuariosfrm"

//Proyecto
import Proyecto from "./paginas/Admin/Pro/Proyecto";
//Remocion de escombros
import Remocion from "./paginas/Admin/Rem/Remocion";
// Obra civil Hangar
import Dicthangar from "./paginas/Admin/Och/Dicthangar";
import Hangararmnt from "./paginas/Admin/Och/Hangararmnt";
import Hangarar from "./paginas/Admin/Och/Hangarar";
import Cibhangar from "./paginas/Admin/Och/Cibhangar";

// Obra civil Administrativos
import Dictoficinas from "./paginas/Admin/Oca/Dictoficinas";
import Oficinasar from "./paginas/Admin/Oca/Oficinasar";
import Oficinasmnt from "./paginas/Admin/Oca/Oficinasmnt";
import Ciboficina from "./paginas/Admin/Oca/Ciboficina";

// Obra civil Administrativos
import Ccctv from "./paginas/Admin/Cct/Ccctv";
import Acondicionados from "./paginas/Admin/Ayc/Acondicionados";
import Cristalesaluminio from "./paginas/Admin/Cya/Cristalesaluminio";
import Contenido from "./paginas/Admin/Cnt/Contenido";
import Concentrado from "./paginas/Admin/Cnt/Concentrado"


import Error404 from "./Error404";


// estadisticas
import Estadisticafgr from "./paginas/Admin/Est/Estadisticafgr";
// IA 
// Configuracion
import Settings from "./paginas/Cnf/Settings";
import Splash2 from "./paginas/Escritorio/Splash2";
// tableros de control

function App() {
  const [usuario, setUsuario] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
   const [autusuario, setAutUsuario] = React.useState([]);
   const perfil =[
    {
      email:''
    }
   ]
     React.useEffect(() => {
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          setAutUsuario(authenticatedUser)         
          setUsuario(authenticatedUser);           
          perfil.email=authenticatedUser.email;
          setAuthState("Dashboard");
         
        } else {
          setUsuario(null);    
          setAuthState("login");
        }
      }
    );
   
    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-[15vh]">Cargando...</div>
    );
  if (authState === "login")
   return <Login setAuthState={setAuthState} autusuario={autusuario} />;
if (usuario)
    return (
      <div>
        <HashRouter >
          <Routes>
            <Route>
              <Route path="/auth/" element={<LayoutAuth />}>
                <Route index element={<LayoutAuth />} />
              </Route>
             
              <Route path="/" element={<LayoutSplash autusuario={autusuario}/>}>
                <Route index elemnt={<Dashboard autusuario={autusuario}/>} /> 
              </Route>       
              <Route path="/" element={<LayoutAd1 autusuario={autusuario}/>}>
              <Route index elemnt={<Dashboard autusuario={autusuario}/>} />  
                 <Route  path="/adm/usuarios"  element={<Usuarioform />} /> 
                 <Route  path="/pro/proyecto"  element={<Proyecto />} />
                 <Route  path="/rem/remocion"  element={<Remocion />} />

                 <Route  path="/och/dicthangar" element={<Dicthangar />} />  
                 <Route  path="/och/hangararmnt" element={<Hangararmnt />} />  
                 <Route  path="/och/hangarar" element={<Hangarar />} />
                 <Route  path="/och/cibhangar" element={<Cibhangar />} /> 

                 <Route  path="/oca/dictoficinas" element={<Dictoficinas />} />  
                 <Route  path="/oca/oficinasar" element={<Oficinasar />} />  
                 <Route  path="/oca/oficinasmnt" element={<Oficinasmnt />} />
                 <Route  path="/oca/ciboficina" element={<Ciboficina />} /> 

                 <Route  path="/cct/cctv" element={<Ccctv />} /> 

                 <Route  path="/ayc/acondicionados" element={<Acondicionados />} /> 

                 <Route  path="/cya/cristalesaluminio" element={<Cristalesaluminio  />} /> 
                 
                 <Route  path="/cnt/contenido" element={<Contenido  />} /> 
                 <Route  path="/cnt/concentrado" element={<Concentrado  />} /> 
                 <Route  path="/est/estaditicafgr" element={<Estadisticafgr />} /> 

                <Route  path="/cnf/settings"  element={<Settings  autusuario={autusuario}/>}/>                
              </Route>             
            </Route>
            {/* Errores */}
            <Route path="*" element={<Error404 />}>
            </Route>
          </Routes>
        </HashRouter >
        {/*<Dashboard
        usuario={usuario}
        setAuthState={setAuthState}
        setUsuario={setUsuario}
    />*/}
      </div>
    );
}

export default App;
