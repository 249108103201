import { React, useState, useEffect, createElement ,useRef ,createContext, useContext} from "react";
import { Link } from "react-router-dom";
import logofooter from "../auth/Login/logo_footer.png";
import logoplaceholder from "../auth/Login/user sinestry placeholder img.png";
import axios from "axios";
import { auth } from "../data/fire";

const Sidebar3 = ({ autusuario }) => {
  const usrimg = autusuario.photoURL;
  const usuario = autusuario.displayName;
  const email = autusuario.email;
  const [expanded, setExpanded] = useState(true);
  const [submenuformal, setSubmenuformal] = useState(false);
  const [submenuobracivil, setSubmenuobracivil] = useState(false);
  // realizar la peticion de permisos
  /*  const params = {
      email: 'manuel.mendoza@sinestry.com'
  };
  const config = {
    method: 'post', // Método HTTP
    url: 'http://localhost:3001/fgr/permisos', // URL de la API
    headers: {
        'Content-Type': 'application/json'
    },
    data: params 
};
   
    postData();
  }, []);
  async function postData() {
    try {
        const response = await axios(config);
        setPermisos(response.data);
        console.log(permisos);
    } catch (error) {
        console.error('Error al consumir la API:', error);
    }
}*/
  useEffect(() => {

    //console.log(autusuario)
  })


  function handleClick() {
    setExpanded((curr) => !curr);
    setSubmenuformal(false);
  }
  function handleClose() {
    auth.signOut();
  }
   
  return (
    <div className={`sidebar ${expanded ? "active" : ""}`}>
      <div className="top">
        <div className="logo">
          <img src={logofooter} alt="" />
        </div>
        <i onClick={handleClick} className="bx bx-menu" id="btn"></i>
      </div>
      <div className="user grid grid-cols-1 items-center ">
      <div className="flex justify-center col-span-1">
        <img
          className={` ${submenuformal ? "hidden" : ""} ${expanded ? " w-[30%] rounded-[50%]" : "w-[45%] rounded-[50%]"}`}
          src={usrimg}
          alt=""
        />
        </div>
        <div className="col-span-1 mb-[6vh]">
          <p className="bold text-[12px]">{usuario}</p>
          <p className="text-[10px]">{email}</p>      
        </div>
      </div>
      <ul className="w-full text-[1.2rem]"  >

        <li>
          <Link
            onClick={() => {
              setExpanded(false);
            }}
            to="/"
          >
            <i
              className={`bx bx-home ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">Inicio</span>
          </Link>
        </li>

        <li>
          <Link
            onClick={() => {
              setSubmenuformal(!submenuformal);
              setSubmenuobracivil(false);
              setExpanded(false);
            }}
           
          >
            <i
              className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">
              {expanded ? "Formal Reclamación" : ""}
            </span>
          </Link>

          <div
            className={`absolute -top-[30vh] h-auto z-50 bg-grisSinestry rounded-md ${submenuformal ? "" : "hidden"} 
           ${expanded ? "w-full ml-[15vw] mr-2" : "ml-[3vw] w-[23vw] rounded-md bg-gray-300 shadow-sm shadow-gray-100 "}`
          }
          >
            <ul className="h-auto">
              {(email=='gustavo.rueda@fgr.sinestryapp.com'||email=='miguel.chavez@fgr.sinestryapp.com'||email=='noe.campoy@fgr.sinestryapp.com')?(""):(
                <li>
                <Link
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                  to="/pro/proyecto"
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Proyecto</span>
                </Link>
              </li>

                )}
            
              <li>
                <Link
                  to="/rem/remocion"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Remoción de escombros</span>
                </Link>
              </li>

              <li>
                <Link                 
                  onClick={() => {
                    setSubmenuobracivil(!submenuobracivil);    
                             
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Obra civil</span>
                </Link>
                <div
            className={`absolute -top-[0vh] h-auto z-50 bg-grisSinestry rounded-md ${submenuobracivil ? "" : "hidden"} 
           ${expanded ? "w-full ml-[17vw] mr-2" : "ml-[14vw] w-[23vw] rounded-md bg-gray-300 shadow-sm shadow-gray-100"}`}
          >  <li>
                <Link
                  to="och/dicthangar"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Obra civil de hangar </span>
                </Link>
              </li>
             
              <li>
                <Link
                  to="/oca/ciboficina"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Obra civil de oficina </span>
                </Link>
              </li>
                </div>
              </li>
            

              <li>
                <Link
                  to="/cct/cctv"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">CCTV </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/cya/cristalesaluminio"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Cristales</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/ayc/acondicionados"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Aire acondicionado (AC)</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/cnt/contenido"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Contenidos</span>
                </Link>
              </li>
              {(email=='gustavo.rueda@fgr.sinestryapp.com'||email=='miguel.chavez@fgr.sinestryapp.com'||email=='noe.campoy@fgr.sinestryapp.com')?(""):(
              <li>
                <Link
                  to="/cnt/concentrado"
                  onClick={() => {
                    setSubmenuformal(!submenuformal);
                    setSubmenuobracivil(false);
                    setExpanded(false);
                  }}
                >
                  <i
                    className={`bx bx-spreadsheet ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
                  ></i>
                  <span className="">Concentrado</span>
                </Link>
              </li>)}
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubmenuobracivil(false);
              setExpanded(false);
            }}
            to="/est/estaditicafgr"
          >
            <i
              className={`bx bx-bar-chart-alt-2 ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">{expanded ? "Estadísticas" : ""}</span>
            </span>
          </Link>
        </li>
        <script type="module" src="https://cdn.jsdelivr.net/npm/@leadsales/leadclick@1.11.1/dist/leadclick/leadclick.esm.js"></script>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubmenuobracivil(false);
              setExpanded(false);
            }}
            to="https://api.whatsapp.com/send?phone=5215578786789&text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!"
            target="_blank"
          >
            <i
              className={`bx bxl-whatsapp ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">
              <span className="nav-item">{expanded ? "Contáctanos" : ""}</span>
            </span>
          </Link>
        </li>
        <li>
          <Link onClick={handleClose}>
            <i
              className={`bx bx-exit ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">
              <span className="nav-item">{expanded ? "Salir" : ""}</span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar3;
