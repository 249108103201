import Restriccion from "../../../components/Restriccion";
import Usuarioform from "../../../components/Admin/Usuarioform"

const Usuariosfrm = () => {
    const  permiso = true;
  if (!permiso) {
   return( <Restriccion />)
  } else {  
    return(  <Usuarioform/>)  
  }
};
export default Usuariosfrm;
