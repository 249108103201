const Permisos = [
  {
    id: 0,
    modulo:'Inicio',
    url:'/',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: true,
    submenu:[],
    parent:0,
    isOpen:false,
    icono:'ri-home-4-line' 
  },
   {
    id: 1,
    modulo:'Formal Reclamación',
    url:'',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: true,
    submenu:[
      {
        id: 1100,
        modulo:'Proyecto',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1101,
            modulo:'Proyecto',
            url:'/pro/proyecto',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1200,
        modulo:'Remoción de escombro',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1201,
            modulo:'Remoción de escombros',
            url:'/rem/remocion',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1300,
        modulo:'Obra civil',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1302,
            modulo:'Obra civil de hangar',
            url:'/och/dicthangar',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
          {
            id: 1305,
            modulo:'Obra civil de oficina',
            url:'/oca/dictoficinas',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1300,
        modulo:'Obra civil oficinas administrativas',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1302,
            modulo:'Dict.Oficinas',
            url:'/oca/dictoficinas',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
          {
            id: 1303,
            modulo:'Oficinas AR',
            url:'/oca/oficinasar',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
          {
            id: 1304,
            modulo:'Oficinas MNT',
            url:'/oca/oficinasmnt',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
          {
            id: 1305,
            modulo:'CIB Oficinas',
            url:'/oca/ciboficina',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1400,
        modulo:'CCTV/Circ.Cerrado de TV',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1401,
            modulo:'CCTV/Circ.Cerrado de TV',
            url:'/cct/cctv',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1600,
        modulo:'Cristales y aluminio',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1601,
            modulo:'Cristales y aluminio',
            url:'/cya/cristalesaluminio',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1600,
        modulo:'AA/Aires acondicionados',
        url:'',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1501,
            modulo:'AA/Aires acondicionados',
            url:'/ayc/acondicionados',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1700,
        modulo:'contenidos',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1701,
            modulo:'Contenidos',
            url:'',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
      {
        id: 1800,
        modulo:'Concentrado',
        correo: "carlos.arreola@fyfasesores.mx",
        permiso: true,
        submenu:[
          {
            id: 1701,
            modulo:'Concentrado',
            url:'/cnt/concentrado',
            correo: "carlos.arreola@fyfasesores.mx",
            permiso: true,
            submenu:[],
            parent:0,
            isOpen:false,
            icono:'ri-car-line'         
          },
        ],
        parent:0,
        isOpen:false,
        icono:'ri-car-line'         
      },
    ],
    parent:0,
    isOpen:false,
    icono:'ri-car-line' 
  },
  {
    id: 3,
    modulo:'Control de facturas',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: false,
    submenu:[   
    ],
    parent:0,
    isOpen:false,
    icono:'ri-calendar-line' 
  },
  {
    id: 4,
    modulo:'Estadísticas',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: true,
    submenu:[],
    parent:0,
    isOpen:false,
    icono:'ri-pie-chart-2-line' 
  },
  {
    id: 5,
    modulo:'Tramo caretero',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: false,
    submenu:[],
    parent:0,
    isOpen:false,
    icono:'ri-road-map-line'
  },
  {
    id:6,
    modulo:'Configuracion',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: false,
    submenu:[{
      id:90,
      modulo:'Permisos',
      correo: "carlos.arreola@fyfasesores.mx",
      permiso: true,
      submenu:[],
      parent:0,
      icono:'ri-settings-5-line'},],
    parent:0,
    icono:'ri-settings-5-line'},
  {
    id:7,
    modulo:'Salir',
    correo: "carlos.arreola@fyfasesores.mx",
    permiso: false,
    submenu:[],
    parent:0,
    icono:'ri-shut-down-line'
  },
];

export default Permisos;
