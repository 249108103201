import logosinestry from "../../auth/Login/logo sinestry fgr.png";
import imagenondas from "../../auth/Login/fondo ondas sinestry.png";
const Encab = () => {
  const titulo=""
  return (
    <div className="">
      <div className={`w-[94%]  ml-[92vw] -mt-[13vh]`}>   
      </div>
    </div>
  );
};
export default Encab;
